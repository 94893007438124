import React, { useContext, useEffect, useState } from "react";
import AppHeader from "../../components/Layout/AppHeader";
import Button, { Variant } from "../../components/Button";
import Textarea from "../../components/Form/Textarea";
import Heading, { Level } from "../../components/Typography/Heading";
import Icon from "../../components/Icon";
import Card from "../../components/Card";
import DataList from "../../components/DataList";
import Select from "../../components/Form/Select";
import { RouteComponentProps } from "react-router-dom";
import { middleware } from "../../plugins/axios";

import { CustomerModal, getLocaleName } from "./CustomerModal";
import { CommunicationModal } from "./CommunicationModal";
import { PaymentModal } from "./PaymentModal";
import { ContractDeleteModal } from "./ContractDeleteModal";
import { ContractModal } from "./ContractModal";
import { OrganisationContext } from "../../providers/OrganisationProvider";
import { toastError, toastSuccess } from "../../plugins/toast";
import SpinningLoader, { SpinnerColor } from "../../components/loader";
import LedgerEntriesModal, {
  setReminderStatusTranslation,
} from "./LedgerEntriesModal";
import NewInsuranceModal from "./components/NewInsuranceModal/NewInsuranceModal";
import ClaimsModal from "./ClaimModal";
import NewClaimModal from "./NewClaim/NewClaimModal";
import PermissionWrapper, {
  HasPermission,
  permissionArrayBehaviour,
  UserPermissions,
} from "../../components/UserPermission";
import { downloadFile } from "../../plugins/fileSaver";
import { UserContext } from "../../providers/UserProvider";
import { formatDate } from "../../utils/formatDate";
import { ContractRetainModal } from "./ContractRetainModal";
import { classNames } from "../../utils/classNames";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";
import CreateTaskModal from "./CreateTaskModal";
import ContractDetailCard from "./ContractDetailCard";
import ContractMultiDeviceDetailCard from "./ContractMutltiDeviceDetailCard";
import { ContractAddProductModal } from "./ContractAddProductModal";
import { AccountModal } from "./AccountModal";

interface SelectOption {
  value: string;
  label: string;
}

export interface ClaimDataOptions {
  id: string;
  claimNo: string;
  contractId: string;
  status: string;
  description: string;
  damageCause: string;
  problemArea: string;
  wrongRepair: boolean;
  underWarranty: boolean;
  isRouted: boolean;
  customerNotification: string;
  date: string;
  repairStatus: string;
  repairStatusLastUpdated: string;
  onHoldReasonCodeDescription: string;
  productDescription: string;
  productId: string;
  shopNo: string;
  shopName: string;
  shopPhoneNo: string;
  routing: string | number;
}

export interface CustomerDataAccount {
  email: string;
}

export interface CustomerDataOptions {
  account: CustomerDataAccount;
  balance: string;
  birth_date: string;
  first_name: string;
  middle_name?: string;
  id: string;
  initials: string;
  phone: string;
  surname: string;
  customer_no: string;
  email: string;
  streetName: string;
  houseNo: string;
  houseNoAddition: string;
  postCode: string;
  city: string;
  phoneNo: string;
  template: string;
  gender: string;
  iban: string;
  noNewsletter: boolean;
  rightToObject: boolean;
  portalAccount: boolean;
  portalAccountEmail: string;
  locale_code: string;
  data: Array<{
    label: string;
    value: string;
    key: string;
    required?: boolean;
  }>;
}

export interface PaymentDataOptions {
  data: Array<{
    label: string;
    value: string;
    key: string;
  }>;
}

export interface ContractProductOptions {
  id: string;
  category_code: string;
  description: string;
  imei: string;
  serial_number: string;
  manufacturer: string;
  object_value: number;
  purchase_date: string;
  claim_exists: boolean;
  ext_line_number: string;
  ext_contract_product_id: string;
}

export interface ContractDataOptions {
  multidevice: boolean;
  collectionItemNo: string;
  manufacturer: string;
  description: string;
  purchaseDate: string;
  objectValue: string;
  iMEI: string;
  category_code: string;
  template_no: string;
  serialNo: string;
  storeOfPurchase: string;
  contract_id: string;
  contract_no: string;
  contract_description: string;
  item_description: string;
  category: string;
  status: string;
  reminder_status: string;
  starting_date: string;
  ending_date: string;
  own_risk_damage_amount: number;
  own_risk_theft_amount: number;
  premium_amount: string;
  min_no_of_periods: number;
  subGroup: string;
  products: Array<ContractProductOptions>;
  theft_coverage: boolean;
  fall_and_impact_damage: string;
  technical_defect: string;
  water_damage: string;
  late_payment_amount: number;
  contractarticlegroup_set?: Array<ContractArticleGroup>;
  data: Array<{
    label: string;
    value: string;
  }>;
}

interface ContractArticleGroup {
  category_code: string;
  description: string;
}

interface NoteDataOptions {
  comment: string;
  date: string;
  organisation: string;
  organisationEmail: string;
}

interface ContractNoteDataOptions extends NoteDataOptions {
  contract_no: string;
}

interface ClaimNoteDataOptions extends NoteDataOptions {
  claim_no: string;
}

export interface TemplateDataOptions {
  template_no: string;
  category_code: string;
  translation_key: string;
  customer_image: string;
  customer_information: string;
  service_information: string;
}

export interface LedgerEntries {
  id: string | null;
  amount: number | null;
  reversal_description: string | null;
  collection_service_status: string | null;
  contract_id: string;
  contract_out_of_sync: boolean;
  contract_has_recent_cancel_request: boolean;
  customer: string;
  description: string | null;
  document_no: string | null;
  document_type: string | null;
  invoicing_period: string | null;
  is_open: string;
  payment_date: string;
  payment_link: boolean | string | null;
  posting_date: string;
  reminder_status: string;
}

const CustomerDetail: React.FC<RouteComponentProps<{ id: string }>> = (
  props
) => {
  const options = [
    {
      value: "user_register",
      label: i18n.t("detail.email.send_portal_account_instructions"),
    },
    {
      value: "user_password_reset",
      label: i18n.t("detail.email.user_password_reset"),
    },
    {
      value: "stay_connected",
      label: i18n.t("detail.email.stay_connected"),
    },
    // {
    //   value: 3,
    //   label: i18n.t("detail.email.send_contract_terms"),
    // },
    // { value: 4, label: i18n.t("detail.email.send_terms") },
  ];

  const loggedInUser = useContext(UserContext).user;
  const organisationUser = useContext(OrganisationContext).organisationUser;
  const organisation = useContext(OrganisationContext).organisation;
  const [isLoading, setIsLoading] = useState(false);
  const [showNewContractModal, setShowNewContractModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);
  const [showContractDeleteModal, setShowContractDeleteModal] = useState(false);
  const [showContractRetainModal, setShowContractRetainModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showClaimsModal, setShowClaimsModal] = useState(false);
  const [showNewClaimModal, setShowNewClaimModal] = useState(false);
  const [showClaimWarningModal, setShowClaimWarningModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showLedgerEntriesModal, setShowLedgerEntriesModal] = useState(false);
  const [showTasksModal, setShowTasksModal] = useState(false);
  const [ledgerEntries, setLedgerEntries] = useState<LedgerEntries[] | null>(
    null
  );
  const [ledgerEntriesLoading, setLedgerEntriesLoading] =
    useState<boolean>(true);
  const [customerData, setCustomerData] = useState<CustomerDataOptions | null>(
    null
  );
  const [paymentData, setPaymentData] = useState<PaymentDataOptions | null>(
    null
  );
  const [contractData, setContractData] = useState<ContractDataOptions[] | []>(
    []
  );
  const [claimData, setClaimData] = useState<ClaimDataOptions[] | []>([]);
  const [templateData, setTemplateData] = useState<TemplateDataOptions | null>(
    null
  );
  const [contractToDeleteData, setContractToDeleteData] =
    useState<ContractDataOptions | null>(null);
  const [contractToRetainData, setContractToRetainData] =
    useState<ContractDataOptions | null>(null);
  const [contractToEditData, setContractToEditData] =
    useState<ContractDataOptions | null>(null);
  const [tasksContractNumber, setTasksContractNumber] = useState("");
  const [contractNoteData, setContractNoteData] = useState<
    ContractNoteDataOptions[] | []
  >([]);
  const [claimNoteData, setClaimNoteData] = useState<
    ClaimNoteDataOptions[] | []
  >([]);
  const [customerMutationPending, setCustomerMutationPending] =
    useState<boolean>(false);
  const [contractDataLoading, setContractDataLoading] = useState<boolean>(true);

  const [selected, setSelected] = useState(options[0]);

  const [contractOptions, setContractOptions] = useState<SelectOption[] | []>(
    []
  );

  const [contractComment, setContractComment] = useState("");

  const [selectedContract, setSelectedContract] = useState({
    value: "",
    label: "",
  });

  const [claimOptions, setClaimOptions] = useState<SelectOption[] | []>([]);
  const [claimComment, setClaimComment] = useState("");
  const [selectedClaim, setSelectedClaim] = useState({
    value: "",
    label: "",
  });

  const getLedgerEntries = () => {
    setIsLoading(true);
    setLedgerEntriesLoading(true);
    const customerID = customerData?.id;
    if (!customerID) {
      setLedgerEntriesLoading(false);
      return;
    }

    middleware
      .get(`customer/service/payments/${customerID}`, {
        // baseURL: 'http://localhost:8000'
      })
      .then((res) => {
        if (!res.data.length) return;

        const apiLedgerEntries: LedgerEntries[] = [];

        Object.values(res.data).forEach((data: any) => {
          apiLedgerEntries.push({
            id: data.id,
            amount: data.amount,
            reversal_description: data.reversal_description,
            collection_service_status: data.collection_service_status,
            contract_id: data.contract?.navision_ext_id || "",
            contract_out_of_sync: data.contract?.out_of_sync || false,
            contract_has_recent_cancel_request:
              data.contract?.has_recent_cancel_request || false,
            customer: data.customer,
            description: data.description,
            document_no: data.document_no,
            document_type: data.document_type,
            invoicing_period: data.invoicing_period,
            is_open: data.is_open
              ? i18n.t("detail.invoices.payment_status.open")
              : i18n.t("detail.invoices.payment_status.paid"),
            payment_date: data.payment_date,
            payment_link: data.payment_link,
            posting_date: data.posting_date,
            reminder_status: data.reminder_status,
          });
        });

        setLedgerEntries(apiLedgerEntries);
        setLedgerEntriesLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLedgerEntriesLoading(false);
      });
  };

  const getCustomerData = () => {
    setIsLoading(true);
    middleware
      .get(`customer/service/${props.match.params.id}`)
      .then((res) => {
        setCustomerData({
          account: res.data["account"]
            ? {
                email: res.data["account"]["email"],
              }
            : { email: "" },
          balance: res.data["balance_lcy"],
          iban: res.data["iban"],
          birth_date: res.data["birth_date"],
          first_name: res.data["first_name"],
          middle_name: res.data["middle_name"],
          id: res.data["id"],
          initials: res.data["initials"],
          phone: res.data["phone"],
          surname: res.data["surname"],
          customer_no: res.data["navision_ext_id"],
          email: res.data["email"],
          streetName: res.data["street_name"],
          houseNo: res.data["street_number"],
          houseNoAddition: res.data["street_number_addition"],
          postCode: res.data["zip_code"],
          city: res.data["city"],
          phoneNo: res.data["phone"],
          template: res.data["provided_by_template_no"],
          gender: res.data["gender"],
          noNewsletter: res.data["no_newsletter"],
          rightToObject: res.data["right_to_object"],
          portalAccount: res.data["portal_account"],
          portalAccountEmail: res.data["portal_account_email"],
          locale_code: res.data["locale_code"],
          data: [
            {
              label: "detail.customer.customer_number",
              value: res.data["navision_ext_id"],
              key: "navision_ext_id",
            },
            {
              label: "detail.customer.email",
              value: res.data["email"],
              key: "email",
            },
            {
              label: "detail.customer.initials",
              value: res.data["initials"],
              key: "initials",
            },
            {
              label: "detail.customer.first_name",
              value: res.data["first_name"] || "-",
              key: "first_name",
            },
            {
              label: "detail.customer.middle_name",
              value: res.data["middle_name"] || "",
              key: "middle_name",
            },
            {
              label: "detail.customer.last_name",
              value: res.data["surname"],
              key: "surname",
            },
            {
              label: "detail.customer.birthdate",
              value: formatDate(res.data["birth_date"]),
              key: "birth_date",
            },
            {
              label: "detail.customer.phone",
              value: res.data["phone"],
              key: "phone",
              required: true,
            },
            {
              label: "detail.customer.street",
              value: `${
                res.data["street_name"] ? `${res.data["street_name"]} ` : ""
              }${
                res.data["street_number"] ? `${res.data["street_number"]} ` : ""
              }${
                res.data["street_number_addition"]
                  ? `${res.data["street_number_addition"]} `
                  : ""
              }`,
              key: "street",
              required: true,
            },
            {
              label: "detail.customer.zip",
              value: res.data["zip_code"],
              key: "zip",
              required: true,
            },
            {
              label: "detail.customer.city",
              value: res.data["city"],
              key: "city",
              required: true,
            },
            {
              label: "detail.customer.locale",
              value: getLocaleName(res.data["locale_code"]),
              key: "locale_code",
              required: true,
            },
            {
              label: "detail.customer.portal_account",
              value: res.data["portal_account"] ? (
                <Translate name={"common.yes"} />
              ) : (
                <Translate name={"common.no"} />
              ),
              key: "portal_account",
              required: true,
            },
          ],
        });
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        setIsLoading(false);
        return;
      });
  };

  const getContractData = () => {
    if (!customerData) {
      return;
    }
    setContractDataLoading(true);
    const id = customerData.id;
    middleware
      .get(`contracts/service/customer_contracts/${id}`)
      .then(async (res) => {
        const data: ContractDataOptions[] = [];
        const selectionData: SelectOption[] = [];

        selectionData.push({
          value: "all",
          label: "All",
        });

        await res.data.forEach((element: any) => {
          data.push({
            subGroup: element.subGroup || "",
            collectionItemNo: element.item_no,
            manufacturer: element.manufacturer,
            description: element.description,
            purchaseDate: formatDate(element.purchase_date),
            objectValue: element.object_value,
            iMEI: element.imei,
            category_code: element.category_code,
            template_no: element.template_no,
            serialNo: element.serial_number,
            storeOfPurchase: element.store,
            contract_id: element.id,
            contract_no: element.navision_ext_id,
            contract_description: element.contract_description,
            item_description: element.description,
            category: element.category,
            status: element.status,
            reminder_status: element.reminder_status,
            own_risk_damage_amount: element.own_risk_damage_amount,
            own_risk_theft_amount: element.own_risk_theft_amount,
            premium_amount: element.premium_amount,
            late_payment_amount: element.late_payment_amount,
            min_no_of_periods: element.min_no_of_periods,
            starting_date: element.starting_date,
            ending_date:
              element.ending_date !== "0001-01-01"
                ? element.ending_date
                : element.calculated_ending_date === "0001-01-01"
                ? ""
                : formatDate(element.calculated_ending_date),
            theft_coverage: element.theft_coverage,
            data: [
              {
                label: "detail.contracts.starting_date",
                value: formatDate(element.starting_date),
              },
              {
                label: "detail.contracts.ending_date",
                value:
                  element.ending_date !== "0001-01-01"
                    ? `${element.ending_date} (${i18n.t(
                        "detail.contracts.ending_date_definitive"
                      )})`
                    : element.calculated_ending_date === "0001-01-01"
                    ? i18n.t("detail.contracts.no_date")
                    : formatDate(element.calculated_ending_date),
              },
              {
                label: "detail.contracts.status",
                value: element.status,
              },
              {
                label: "detail.contracts.contract_number",
                value: element.navision_ext_id,
              },
              {
                label: "detail.contracts.store",
                value: element.store,
              },
              {
                label: "detail.contracts.claim_count",
                value: element.claims_count,
              },
              {
                label: "detail.contracts.value",
                value: `€${element.object_value}`,
              },
              {
                label: "detail.contracts.imei",
                value: element.imei !== "" ? element.imei : "-",
              },
              {
                label: "detail.contracts.serial_no",
                value:
                  element.serial_number !== "" ? element.serial_number : "-",
              },
              {
                label: "detail.contracts.min_no_of_periods",
                value:
                  element.min_no_of_periods !== ""
                    ? element.min_no_of_periods
                    : "-",
              },
            ],
            products: element.product_set,
            multidevice: element.multidevice,
            contractarticlegroup_set: element.contractarticlegroup_set,
            fall_and_impact_damage: element.fall_and_impact_damage,
            technical_defect: element.technical_defect,
            water_damage: element.water_damage,
          });

          selectionData.push({
            value: element.navision_ext_id,
            label: element.navision_ext_id,
          });
        });
        setContractData(
          data.sort((a, b) => {
            if (a.ending_date === b.ending_date) return 0;
            if (a.ending_date === "") return -1;
            if (b.ending_date === "") return 1;
            if (new Date(a.ending_date) > new Date(b.ending_date)) return 1;
            else return -1;
          })
        );

        setContractOptions(selectionData);
        if (selectionData.length > 0) setSelectedContract(selectionData[0]);
        setContractDataLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toastError(i18n.t("detail.contracts.loading_error"), error.message);
        setContractDataLoading(false);
      });
  };

  const getPaymentData = () => {
    if (!customerData || !contractData || contractData.length === 0) {
      return;
    }
    const id = customerData.id;
    setIsLoading(true);
    middleware
      .get(`customer/service/ibans/${id}`)
      .then((res) => {
        if (res.data.length > 0) {
          let contract = contractData.find(
            (c) => c.contract_no === res.data[0]["contractNo"]
          );
          var data: PaymentDataOptions = {
            data: [
              {
                label: "detail.payment.contract_number",
                value: res.data[0]["contractNo"],
                key: "contractNo",
              },
              {
                label: "detail.payment.iban",
                value: res.data[0]["iban"],
                key: "iban",
              },
            ],
          };
          if (contract) {
            data.data.push({
              label: "detail.payment.reminder_status",
              value: setReminderStatusTranslation(contract.reminder_status),
              key: "reminderStatus",
            });
          }
          data.data.push({
            label: "detail.payment.balance_amount",
            value: "€" + customerData.balance,
            key: "balanceAmount",
          });
          data.data.push({
            label: "detail.payment.payment_method",
            value: res.data[0]["paymentMethodCode"],
            key: "paymentMethodCode",
          });

          setPaymentData(data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const getClaimData = () => {
    if (!customerData) return;

    middleware
      .get(`claims/service/customer/${customerData.id}`)
      .then((res) => {
        const data: ClaimDataOptions[] = [];
        const selectionData: SelectOption[] = [];
        res.data.forEach((element: any) => {
          const claim: ClaimDataOptions = {
            id: element["id"],
            claimNo: element["navision_ext_id"],
            contractId: element.contract.id,
            status: element["status"],
            description: element["damage_description"],
            damageCause: element["damage_cause"],
            problemArea: element["problem_area"],
            wrongRepair: element["wrong_repair"],
            underWarranty: element["under_warranty"],
            isRouted: element["is_routed"],
            date: element["damage_date"],
            customerNotification: element["customer_notification"],
            repairStatus: element["repair_status"],
            repairStatusLastUpdated: element["repair_status_last_updated"],
            onHoldReasonCodeDescription: element["on_hold_reason_code_description"],
            productDescription: element["product_description"],
            productId: element["product_id"],
            shopNo: element["shop_no"],
            shopName: element["shop_name"],
            shopPhoneNo: element["shop_phone_no"],
            routing: element["routing"],
          };
          data.push(claim);
          selectionData.push({
            value: element["navision_ext_id"],
            label: element["navision_ext_id"],
          });
        });
        setClaimData(data);
        setClaimOptions(selectionData);
      })
      .catch((err) => {
        console.error(err);
        toastError(i18n.t("detail.claim.loading_error"), err.message);
      });
  };

  const getContractNotesData = async () => {
    const data: ContractNoteDataOptions[] = [];

    await Promise.all(
      contractData.map(async (ctr) => {
        await middleware
          .get(`service/comments/contract/${ctr.contract_no}`)
          .then((res) => {
            res.data.forEach((element: any) => {
              data.push({
                contract_no: element.contractNo,
                comment: element.comment,
                organisation: element.organisation,
                organisationEmail: element.organisationEmail,
                date: element.date,
              });
            });
          });
      })
    );

    setContractNoteData(data.sort((a, b) => a.date.localeCompare(b.date)));
    setIsLoading(false);
  };

  const getClaimNotesData = async () => {
    const data: ClaimNoteDataOptions[] = [];
    await Promise.all(
      claimData.map(async (claim) => {
        if (!claim.claimNo) return;
        await middleware
          .get(`service/comments/claim/${claim.claimNo}`)
          .then((res) => {
            if (!res.data.value) return;
            res.data.value.forEach((element: any) => {
              data.push({
                claim_no: element.claimNo,
                comment: element.comment,
                organisation: element.organisation,
                organisationEmail: element.organisationEmail,
                date: element.date,
              });
            });
          });
      })
    );

    setClaimNoteData(data.sort((a, b) => a.date.localeCompare(b.date)));
    setIsLoading(false);
  };

  const handleEmailSubmit = (evt: { preventDefault: () => void }) => {
    evt.preventDefault();
    const payload = {
      email: selected.value,
    };
    middleware
      .post(`customer/service/${customerData?.id}/email`, payload)
      .then((res) => {
        toastSuccess(i18n.t("detail.email.success"));
      })
      .catch((e) =>
        e.response.data
          ? toastError(e.response.data, e.message)
          : toastError(i18n.t("detail.email.error"), e.message)
      );
  };

  const toggleCustomerModal = (show: boolean, updated = false) => {
    setShowCustomerModal(show);
    if (updated) {
      getCustomerData();
    }
  };

  const toggleCommunicationModal = (show: boolean, updated = false) => {
    setShowCommunicationModal(show);
  };

  const toggleAccountModal = (show: boolean, updated = false) => {
    setShowAccountModal(show);
    if (updated) {
      getCustomerData();
    }
  };

  const downloadDocument = async (contract: string, type: string) => {
    const response = await middleware.get(
      `contracts/${contract}/download/${type}`
    );
    downloadFile(response.data.dataUri, response.data.filename);
  };

  const emailDocument = (contract: string, type: string) => {
    middleware
      .get(`contracts/${contract}/email/${type}`)
      .then(() => {
        toastSuccess(i18n.t("detail.email.success"));
      })
      .catch((e) => {
        console.log(e);
        toastError(i18n.t("detail.email.error"), e.message);
      });
  };

  const togglePaymentModal = (show: boolean, updated = false) => {
    setShowPaymentModal(show);

    if (!updated) return;

    if (customerData) getPaymentData();
  };

  const toggleLedgerEntriesModal = (show: boolean) => {
    setShowLedgerEntriesModal(show);
  };

  const toggleContractModal = (show: boolean, updated = false) => {
    setShowContractModal(show);
    if (updated) {
      if (customerData) getContractData();
    }
  };

  const toggleContractDeleteModal = (show: boolean, updated = false) => {
    setShowContractDeleteModal(show);
    if (!show) {
      setContractToDeleteData(null);
    }
    if (updated) {
      if (customerData) getContractData();
    }
  };

  const contractHasClaims = (contract: ContractDataOptions) => {
    if (!claimData) return false;
    for (let i = 0; i < claimData.length; i++) {
      if (claimData[i].contractId === contract.contract_id) return true;
    }
    return false;
  };

  const contractOpenClaims = (contract: ContractDataOptions) => {
    if (!claimData) return false;

    const openStatus = ["open", "on hold", "accepted", ""];

    for (let i = 0; i < claimData.length; i++) {
      if (
        claimData[i].contractId === contract.contract_id &&
        openStatus.includes(claimData[i].status.toLowerCase())
      )
        return true;
    }

    return false;
  };

  const organisationIsHarmony = (organisation: string) => {
    organisation = organisation.toLowerCase();
    return organisation.includes("harmony");
  };

  const handleContractNoteSubmit = (evt: { preventDefault: () => void }) => {
    evt.preventDefault();

    if (contractComment === "") return;

    let contracts = [selectedContract];
    if (selectedContract.value === "all") {
      contracts = contractOptions.filter(
        (contract) => contract.value !== "all"
      );
    }

    contracts.forEach((contract) => {
      if (contract.value !== "all") {
        middleware
          .post(`service/comments/contract/${contract.value}/insert`, {
            comment: contractComment,
            organisation: organisation!.name,
            organisationEmail: organisationUser!.email,
          })
          .then(() => {
            setContractComment("");
            getContractNotesData();
          })
          .catch((error) => {
            toastError(i18n.t("detail.notes.error"), error.message);
            console.error(error);
          });
      }
    });
  };

  const handleClaimNoteSubmit = (evt: { preventDefault: () => void }) => {
    evt.preventDefault();
    if (claimComment === "") return;
    middleware
      .post(`service/comments/claim/${selectedClaim.value}/insert`, {
        comment: claimComment,
        organisation: organisation!.name,
        organisationEmail: organisationUser!.email,
      })
      .then(() => {
        setClaimComment("");
        getClaimNotesData();
      })
      .catch((error) => {
        toastError(i18n.t("detail.notes.error"), error.message);
        console.error(error);
      });
  };

  const handleClaimsSubmit = () => {
    setShowNewClaimModal((showNewClaimModal) => !showNewClaimModal);
  };

  const getNoteOrganisation = (organisation: any, note: any) => {
    if (
      organisation?.name === note.organisation ||
      organisation?.name === "Harmony"
    ) {
      return `${note.organisation} - ${note.organisationEmail}`;
    }
    return note.organisation;
  };

  const canViewNote = (organisation: any, note: any) => {
    if (HasPermission(UserPermissions.COMMENTS_VIEW_ALL)) {
      return true;
    }

    if (!HasPermission(UserPermissions.COMMENTS_VIEW_ORGANISATION_ONLY)) {
      return false;
    }

    return organisation?.name === note.organisation;
  };

  const canCreateClaim = (contract: ContractDataOptions): boolean => {
    return (
      contract.status.toLowerCase() === "active" &&
      (!contractOpenClaims(contract) || contract.multidevice)
    );
  };

  const hasOpenPayment = (contract: ContractDataOptions): boolean => {
    return (
      contract.late_payment_amount > 0
    );
  };

  const sendCustomerClaimMail = (claim: ClaimDataOptions, type: string) => {
    triggerClaimMail(claim, type)
        .then((res) => {
          if (res !== undefined) {
            if (res.status === 200) {
              toastSuccess("Claim mail successfully sent");
            } else {
              toastError("Claim mail failed to sent");
            }
          }
        }).catch(() => {
          toastError("Claim mail failed to sent");
        })
  }

  const sendCustomerContractMail = (contract: ContractDataOptions, type: string) => {
    triggerContractMail(contract, type)
        .then((res) => {
          if (res !== undefined) {
            if (res.status === 200) {
              toastSuccess("Contract mail successfully sent");
            } else {
              toastError("Contract mail failed to sent");
            }
          }
        })
        .catch(() => {
          toastError("Contract mail failed to sent");
        })
  }

  const triggerClaimMail = async (claim: ClaimDataOptions|null, type: string) => {
    return await middleware.post(`customer/service/${customerData?.id}/trigger_email`, {
      claim_id: claim?.id,
      type: type,
      entity: "claim"
    })
  }

  const triggerContractMail = async (contract: ContractDataOptions|null, type: string) => {
    return await middleware.post(`customer/service/${customerData?.id}/trigger_email`, {
      contract_id: contract?.contract_id,
      type: type,
      entity: "contract"
    })
  }

  useEffect(getCustomerData, [props.match.params.id]);
  useEffect(getContractData, [customerData]);
  useEffect(getPaymentData, [contractData]);
  // useEffect(getLedgerEntries, [customerData?.id]);
  useEffect(getClaimData, [customerData]);
  useEffect(() => {
    getContractNotesData();
  }, [contractData]);
  useEffect(() => {
    getClaimNotesData();
  }, [claimData]);

  return (
    <>
      <AppHeader>
        <div className="col-span-1 flex flex-row items-center">
          <Icon name="icon_userinformation" classList="mr-2" />

          <Heading tag="h1" level={Level.HEADING_2}>
            {customerData ? customerData.customer_no : ""}
          </Heading>
        </div>
      </AppHeader>

      <div className="p-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex flex-col">
          <Card>
            <Card.Header cols="grid-cols-2" colSpan="col-span-full">
              <div className="col-span-full md:col-span-1 flex flex-row items-center">
                <Icon name="icon_userinformation" classList="mr-2" />

                <Heading tag="h2" level={Level.HEADING_3}>
                  <Translate name={"detail.customer.header"} />
                </Heading>
              </div>

              <div className="col-span-full md:col-span-1 md:text-right">
                <PermissionWrapper
                  permissionSlug={UserPermissions.CUSTOMER_CHANGES}
                >
                  <div
                    onClick={() =>
                      setShowCommunicationModal(
                        (showCommunicationModal) => !showCommunicationModal
                      )
                    }
                    className="text-sm text-right text-purple-700 underline cursor-pointer"
                  >
                    <Translate name={"detail.communication.edit"} />
                  </div>
                  {customerData && customerData.account.email != "" && (
                    <div
                      onClick={() =>
                        setShowAccountModal(
                          (showAccountModal) => !showAccountModal
                        )
                      }
                      className="text-sm text-right text-purple-700 underline cursor-pointer"
                    >
                      <Translate name={"detail.customer.account_edit"} />
                    </div>
                  )}
                  <div
                    onClick={() =>
                      setShowCustomerModal(
                        (showCustomerModal) => !showCustomerModal
                      )
                    }
                    className="text-sm text-right text-purple-700 underline cursor-pointer"
                  >
                    <Translate name={"detail.customer.edit"} />
                  </div>
                </PermissionWrapper>
              </div>
            </Card.Header>
            {customerMutationPending ? (
              <div className="col-span-full text-purple-700 font-semibold">
                <Translate name={"detail.customer.mutation_open"} />
              </div>
            ) : null}
            {customerData ? (
              <DataList data={customerData ? customerData.data : []} />
            ) : (
              <div className="h-100 flex justify-center">
                <SpinningLoader color={SpinnerColor.PURPLE} />
              </div>
            )}
            {customerData &&
              (organisationIsHarmony(organisation?.name) ||
                organisation?.name === "NotiveTEST") && (
                <a
                  className={classNames(
                    "flex items-center flex-shrink ml-auto mt-4 inline-block py-2 px-4 text-sm leading-6 text-center font-bold rounded cursor-pointer  disabled:opacity-50 disabled:cursor-text",
                    "text-purple-700 bg-purple-300 border border-purple-700"
                  )}
                  href={`https://xtendis.harmony.nl/web/searchdocuments.aspx?archiefid=2&klantnummer=${customerData.customer_no}&launch=doc`}
                  target="_blank"
                >
                  <Icon name="external-link" classList="w-5 mr-2" />
                  xtendis
                </a>
              )}
          </Card>
        </div>

        <div className="flex flex-col">
          <Card>
            <Card.Header cols="grid-cols-2" colSpan="col-span-full">
              <div className="col-span-full md:col-span-1 flex flex-row items-center">
                <Icon name="icon_userinformation" classList="mr-2" />

                <Heading tag="h2" level={Level.HEADING_3}>
                  <Translate name={"detail.payment.header"} />
                </Heading>
              </div>

              <div className="col-span-full md:col-span-1 md:text-right">
                <PermissionWrapper
                  permissionSlug={UserPermissions.CUSTOMER_CHANGES}
                >
                  <div
                    onClick={() =>
                      setShowPaymentModal(
                        (setShowPaymentModal) => !setShowPaymentModal
                      )
                    }
                    className="text-sm text-right text-purple-700 underline cursor-pointer"
                  >
                    <Translate name={"detail.payment.edit"} />
                  </div>
                </PermissionWrapper>
              </div>
            </Card.Header>
            <DataList data={paymentData ? paymentData.data : []} />

            <PermissionWrapper permissionSlug={UserPermissions.VIEW_PAYMENTS}>
              <table className="w-full">
                <tbody className="bg-white divide-y divide-gray-300 border-t border-b border-gray-300">
                  <tr>
                    <td className="pr-6 py-3 whitespace-nowrap">
                      <div className="flex flex-col justify-center">
                        <div className="text-base font-bold pb-1">
                          <Translate name={"detail.payment.invoices"} />
                        </div>
                        <div className="text-base font-base">
                          <button
                            className="text-purple"
                            onClick={() => {
                              setShowLedgerEntriesModal(
                                (showLedgerEntriesModal) =>
                                  !showLedgerEntriesModal
                              );
                              getLedgerEntries();
                            }}
                          >
                            <Translate name={"detail.payment.view_invoices"} />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </PermissionWrapper>
          </Card>
        </div>

        <PermissionWrapper permissionSlug={UserPermissions.SEND_EMAIL}>
          <div className="col-span-full flex flex-wrap">
            <div className="flex items-center w-full md:w-auto">
              <Heading classList="mr-4" tag="h2" level={Level.HEADING_5}>
                <Translate name={"detail.email.header"} />
              </Heading>
            </div>

            <div className="mr-4 w-full md:w-1/4">
              <Select
                selected={selected}
                setSelected={setSelected}
                id="email"
                name="email"
                options={options}
              />
            </div>

            <Button onClick={handleEmailSubmit} variant={Variant.LIGHT_PURPLE}>
              <Translate name={"detail.email.submit"} />
            </Button>
          </div>
        </PermissionWrapper>

        <div className="flex flex-col">
          <div className="flex">
            <div className="flex-grow">
              <Heading tag="h2" level={Level.HEADING_2}>
                <Translate name={"detail.contracts.header"} />
              </Heading>
            </div>
            <div className="">
              <PermissionWrapper
                permissionSlug={UserPermissions.CREATE_INSURANCE_POLICY}
              >
                <Button
                  onClick={() => setShowNewContractModal(true)}
                  variant={Variant.TRANSPARENT}
                >
                  <Translate name={"detail.contracts.new"} />
                </Button>
              </PermissionWrapper>
            </div>
          </div>
          {!customerData || contractDataLoading ? (
            <Card>
              <div className="h-100 flex justify-center">
                <SpinningLoader color={SpinnerColor.PURPLE} />
              </div>
            </Card>
          ) : (
            (contractData as ContractDataOptions[]).map(
              (contract: ContractDataOptions, i: number) => {
                if (!contract.multidevice) {
                  return (
                    <ContractDetailCard
                      key={i}
                      contract={contract}
                      setShowContractModal={setShowContractModal}
                      setContractToEditData={setContractToEditData}
                      setShowTasksModal={setShowTasksModal}
                      setTasksContractNumber={setTasksContractNumber}
                      setShowContractDeleteModal={setShowContractDeleteModal}
                      setContractToDeleteData={setContractToDeleteData}
                      setShowContractRetainModal={setShowContractRetainModal}
                      setShowClaimsModal={setShowClaimsModal}
                      setContractToRetainData={setContractToRetainData}
                      sendCustomerContractMail={sendCustomerContractMail}
                      handleClaimsSubmit={handleClaimsSubmit}
                      contractHasClaims={contractHasClaims}
                      downloadDocument={downloadDocument}
                      emailDocument={emailDocument}
                    />
                  );
                } else {
                  return (
                    <ContractMultiDeviceDetailCard
                      key={i}
                      customer={customerData}
                      contract={contract}
                      setShowContractModal={setShowContractModal}
                      setContractToEditData={setContractToEditData}
                      setShowTasksModal={setShowTasksModal}
                      setTasksContractNumber={setTasksContractNumber}
                      setShowContractDeleteModal={setShowContractDeleteModal}
                      setContractToDeleteData={setContractToDeleteData}
                      setShowContractRetainModal={setShowContractRetainModal}
                      setShowClaimsModal={setShowClaimsModal}
                      setContractToRetainData={setContractToRetainData}
                      setShowAddProductModal={setShowAddProductModal}
                      handleClaimsSubmit={handleClaimsSubmit}
                      contractHasClaims={contractHasClaims}
                      downloadDocument={downloadDocument}
                    />
                  );
                }
              }
            )
          )}
        </div>

        <PermissionWrapper
          permissionSlug={[
            UserPermissions.COMMENTS_VIEW_ALL,
            UserPermissions.COMMENTS_VIEW_ORGANISATION_ONLY,
          ]}
          permissionArrayBehaviour={permissionArrayBehaviour.OR}
        >
          <div className="flex flex-col">
            <Heading tag="h2" level={Level.HEADING_2}>
              <Translate name={"detail.notes.contract.header"} />
            </Heading>

            <Card classList="mt-8">
              <form>
                <Textarea
                  id="comment"
                  label=""
                  translationKey="detail.notes.contract.comment_label"
                  placeholder={i18n.t(
                    "detail.notes.contract.comment_placeholder"
                  )}
                  value={contractComment}
                  onChange={(e) => setContractComment(e.target.value)}
                />
                <div className="flex flex-wrap text-sm mt-4 mb-4">
                  <div className="flex-grow font-bold">
                    <Select
                      selected={selectedContract}
                      setSelected={setSelectedContract}
                      id="contract"
                      name="contract"
                      options={contractOptions}
                    />
                  </div>
                  <div className="ml-4">
                    <Button
                      type="button"
                      variant={Variant.LIGHT_PURPLE}
                      onClick={handleContractNoteSubmit}
                    >
                      <Translate name={"detail.notes.contract.save"} />
                    </Button>
                  </div>
                </div>
              </form>

              <Heading
                tag="h3"
                level={Level.HEADING_5}
                classList="pb-4 mb-4 text-lg text-purple-700 border-b border-gray-300"
              >
                <Translate name={"detail.notes.contract.recent_header"} />
              </Heading>
              <div
                style={{ height: "300px" }}
                className="overflow-y-scroll pr-6"
              >
                {contractNoteData
                  .map((note, i) => {
                    return (
                      canViewNote(organisation, note) && (
                        <div key={i} className="flex flex-wrap text-sm mb-4">
                          <div className="flex-grow font-bold">
                            {note.contract_no}
                          </div>
                          <div className="mb-4 w-full">{note.comment}</div>
                          <div className="flex-grow font-bold">
                            {getNoteOrganisation(organisation, note)}
                          </div>
                          <div className="">{note.date}</div>
                        </div>
                      )
                    );
                  })
                  .reverse()}
              </div>
            </Card>

            <Heading classList="mt-8" tag="h2" level={Level.HEADING_2}>
              <Translate name={"detail.notes.claim.header"} />
            </Heading>

            <Card classList="mt-8">
              <form>
                <Textarea
                  id="comment-claims"
                  label=""
                  translationKey="detail.notes.claim.comment_label"
                  placeholder={i18n.t("detail.notes.claim.comment_placeholder")}
                  value={claimComment}
                  onChange={(e) => setClaimComment(e.target.value)}
                />
                <div className="flex flex-wrap text-sm mt-4 mb-4">
                  <div className="flex-grow font-bold">
                    <Select
                      selected={selectedClaim}
                      setSelected={setSelectedClaim}
                      id="claim"
                      name="claim"
                      options={claimOptions}
                    />
                  </div>
                  <div className="ml-4">
                    <Button
                      type="button"
                      variant={Variant.LIGHT_PURPLE}
                      onClick={handleClaimNoteSubmit}
                    >
                      <Translate name={"detail.notes.claim.save"} />
                    </Button>
                  </div>
                </div>
              </form>

              <Heading
                tag="h3"
                level={Level.HEADING_5}
                classList="pb-4 mb-4 text-lg text-purple-700 border-b border-gray-300"
              >
                <Translate name={"detail.notes.claim.recent_header"} />
              </Heading>
              <div
                style={{ height: "300px" }}
                className="overflow-y-scroll pr-6"
              >
                {claimNoteData
                  .map((note, i) => {
                    return (
                      canViewNote(organisation, note) && (
                        <div key={i} className="flex flex-wrap text-sm mb-4">
                          <div className="flex-grow font-bold">
                            {note.claim_no}
                          </div>
                          <div className="mb-4 w-full">{note.comment}</div>
                          <div className="flex-grow font-bold">
                            {getNoteOrganisation(organisation, note)}
                          </div>
                          <div className="">{note.date}</div>
                        </div>
                      )
                    );
                  })
                  .reverse()}
              </div>
            </Card>
          </div>
        </PermissionWrapper>
      </div>

      {/* Modals */}
      {!!contractToEditData && !!customerData && (
        <ContractModal
          isOpen={showContractModal}
          setIsOpen={toggleContractModal}
          contractToEdit={contractToEditData}
          organisationEmail={organisationUser!.email}
          organisation={organisation}
          customer={customerData}
        />
      )}
      {!!contractToEditData && !!customerData && (
        <ContractAddProductModal
          isOpen={showAddProductModal}
          setIsOpen={toggleContractModal}
          contractToEdit={contractToEditData}
          organisationEmail={organisationUser!.email}
          organisation={organisation}
          customer={customerData}
        />
      )}
      {!!tasksContractNumber && !!customerData && (
        <CreateTaskModal
          isOpen={showTasksModal}
          setIsOpen={setShowTasksModal}
          contractNumber={tasksContractNumber}
          customer_id={customerData.id}
        ></CreateTaskModal>
      )}
      {!!customerData && (
        <NewInsuranceModal
          isOpen={showNewContractModal}
          setIsOpen={setShowNewContractModal}
          organisationEmail={organisationUser!.email}
          organisation={organisation}
          customer={customerData}
        />
      )}
      {!!contractToDeleteData && (
        <ContractDeleteModal
          customerId={customerData!.id}
          organisation={organisation}
          organisationEmail={organisationUser!.email}
          contractToDelete={contractToDeleteData}
          isOpen={showContractDeleteModal}
          setIsOpen={toggleContractDeleteModal}
        />
      )}

      <PermissionWrapper permissionSlug={UserPermissions.CUSTOMER_RETENTION}>
        {!!contractToRetainData && (
          <ContractRetainModal
            customerId={customerData!.id}
            organisation={organisation}
            organisationEmail={organisationUser!.email}
            contractToRetain={contractToRetainData}
            isOpen={showContractRetainModal}
            setIsOpen={setShowContractRetainModal}
          />
        )}
      </PermissionWrapper>

      {!!contractToEditData && !!claimData && (
        <ClaimsModal
          isOpen={showClaimsModal}
          setIsOpen={setShowClaimsModal}
          sendCustomerClaimMail={sendCustomerClaimMail}
          claims={claimData}
          contract={contractToEditData}
        />
      )}

      {!!contractToEditData && !!customerData && !!paymentData && (
        <NewClaimModal
          isOpen={showNewClaimModal}
          setIsOpen={setShowNewClaimModal}
          contract={contractToEditData}
          customer={customerData}
          paymentInformation={paymentData}
          organisation={organisation}
          organisationEmail={organisationUser!.email}
          canCreateClaim={canCreateClaim(contractToEditData)}
          hasOpenPayment={hasOpenPayment(contractToEditData)}
          openUserEditModal={() => {
            setShowCustomerModal(true);
          }}
          openPaymentEditModal={() => {
            setShowPaymentModal(true);
          }}
          openContractEditModal={() => {
            setShowContractModal(true);
          }}
        />
      )}
      {!!customerData && (
        <div>
          <CustomerModal
            loggedInUser={loggedInUser}
            isOpen={showCustomerModal}
            setIsOpen={toggleCustomerModal}
            organisation={organisation}
            organisationEmail={organisationUser!.email}
            customer={customerData}
          />
          <CommunicationModal
            loggedInUser={loggedInUser}
            isOpen={showCommunicationModal}
            setIsOpen={toggleCommunicationModal}
            organisation={organisation}
            organisationEmail={organisationUser!.email}
            customer={customerData}
          />
          <AccountModal
            loggedInUser={loggedInUser}
            isOpen={showAccountModal}
            setIsOpen={toggleAccountModal}
            organisation={organisation}
            organisationEmail={organisationUser!.email}
            customer={customerData}
          />
        </div>
      )}

      {!!paymentData && (
        <PaymentModal
          loggedInUser={loggedInUser}
          contracts={contractData}
          customerId={customerData!.id}
          organisation={organisation}
          organisationEmail={organisationUser!.email}
          isOpen={showPaymentModal}
          setIsOpen={togglePaymentModal}
          paymentData={paymentData}
        />
      )}

      {!!ledgerEntries && !!customerData && (
        <LedgerEntriesModal
          customerId={props.match.params.id}
          customer={customerData}
          isOpen={showLedgerEntriesModal}
          setIsOpen={toggleLedgerEntriesModal}
          ledgerEntries={ledgerEntries}
        />
      )}
    </>
  );
};

export default CustomerDetail;

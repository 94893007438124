import React, { useContext } from "react";
import { OrganisationContext } from "../providers/OrganisationProvider";
import i18n from "../plugins/i18n";

interface PermissionProps {
  user?: {
    active: boolean;
    email: string;
    firebase_uid: string;
    id: string;
    organisation: string;
    role: string;
    first_name: string;
    last_name: string;
    service_introduction: boolean;
    permissions: Array<string>;
  };
  permissionSlug: string | string[];
  permissionArrayBehaviour?: permissionArrayBehaviour;
}

export enum permissionArrayBehaviour {
  OR = 'or',
  AND = 'and',
}

interface Permission {
  slug: string;
  description: string;
}

export enum UserPermissions {
  VIEW_PAYMENTS = "view_payments",
  CREATE_INSURANCE_POLICY = "create_insurance_policy",
  EDIT_INSURANCE_POLICY = "edit_insurance_policy",
  CANCEL_INSURANCE_POLICY = "cancel_insurance_policy",
  CREATE_CLAIMS = "create_claims",
  CREATE_CLAIMS_ALL = "create_claims_all",
  CREATE_CLAIMS_THEFT = "create_claims_theft",
  CREATE_CLAIMS_DAMAGE = "create_claims_damage",
  DISABLE_PHOTO_FUNCTIONALITY = "disable_photo_functionality",
  CUSTOMER_RETENTION = "customer_retention",
  CUSTOMER_CHANGES = "customer_changes",
  SEND_EMAIL = "send_email",
  COMMENTS_VIEW_ALL = "comments_view_all",
  COMMENTS_VIEW_ORGANISATION_ONLY = "comments_view_organisation_only",
}

const Permissions: Permission[] = [
  {
    slug: UserPermissions.VIEW_PAYMENTS,
    description: i18n.t("permissions.view_payments"),
  },
  {
    slug: UserPermissions.CREATE_INSURANCE_POLICY,
    description: i18n.t("permissions.create_insurance"),
  },
  {
    slug: UserPermissions.EDIT_INSURANCE_POLICY,
    description: i18n.t("permissions.edit_insurance"),
  },
  {
    slug: UserPermissions.CANCEL_INSURANCE_POLICY,
    description: i18n.t("permissions.cancel_insurance"),
  },
  {
    slug: UserPermissions.CREATE_CLAIMS,
    description: i18n.t("permissions.create_claims"),
  },
  {
    slug: UserPermissions.CREATE_CLAIMS_ALL,
    description: i18n.t("permission.create_claims_all"),
  },
  {
    slug: UserPermissions.CREATE_CLAIMS_THEFT,
    description: i18n.t("permission.create_claims_theft"),
  },
  {
    slug: UserPermissions.CREATE_CLAIMS_DAMAGE,
    description: i18n.t("permission.create_claims_damage"),
  },
  {
    slug: UserPermissions.DISABLE_PHOTO_FUNCTIONALITY,
    description: i18n.t("permissions.disable_photo_function"),
  },
  {
    slug: UserPermissions.CUSTOMER_RETENTION,
    description: i18n.t("permission.customer_retention"),
  },
  {
    slug: UserPermissions.CUSTOMER_CHANGES,
    description: i18n.t("permission.customer_changes"),
  },
  {
    slug: UserPermissions.SEND_EMAIL,
    description: i18n.t("permission.send_email"),
  },
  {
    slug: UserPermissions.COMMENTS_VIEW_ALL,
    description: i18n.t("permission.comments_view_all"),
  },
  {
    slug: UserPermissions.COMMENTS_VIEW_ORGANISATION_ONLY,
    description: i18n.t("permission.comments_organisation_only")
  },
];

export const HasPermission = (
  permissionSlug: string,
) => {
  const organisation = useContext(OrganisationContext);
  if(!organisation?.organisation?.permissions) {
    return false;
  }

  if(organisation.organisation.permissions.findIndex((p: string) => p === permissionSlug) === -1) {
    return false;
  }

  const user = organisation.organisationUserInfo;

  if(!user?.permissions) {
    return false;
  }

  return user.permissions.findIndex((p: string) => p === permissionSlug) !== -1;
};

export const HasUserPermission = (
  permissionSlug: string,
  userPermissions?: string[],
) => {
  if(!userPermissions) return false;
  return userPermissions.findIndex((p: string) => p === permissionSlug) !== -1;
}

const PermissionWrapper: React.FC<PermissionProps> = (props) => {
  if(props.permissionSlug instanceof Array) {
    if(!props.permissionArrayBehaviour) {
      throw new Error('permission array given without array behaviour to permission wrapper');
    }

    let hasPermission: boolean = false
    for(let i = 0; i < props.permissionSlug.length; i++) {
      if(!HasPermission(props.permissionSlug[i])) {
        if(props.permissionArrayBehaviour === permissionArrayBehaviour.AND) {
          return <></>;
        }
        continue;
      }
      hasPermission = true;
    }

    if(!hasPermission) {
      return <></>;
    }
  }else{
    if (!HasPermission(props.permissionSlug)) {
      return <></>;
    }
  }

  return <>{props.children}</>;
};

export default Object.assign(PermissionWrapper, {
  Permissions,
});

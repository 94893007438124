import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import Checkbox from "../../../components/Form/Checkbox";
import CheckButtonList from "../../../components/Form/CheckButtonList";
import Select from "../../../components/Form/Select";
import Textarea from "../../../components/Form/Textarea";
import useInterval from "../../../utils/UseInterval";
import ClaimConfigurationData from "../../../assets/json/claim_configuration.json";

import {
  ClaimConfigurationCause,
  ClaimType,
  ObjectCategory,
  claimTypeOptions,
  DamageType,
} from "../../../utils/claimValues";
import {
  ContractDataOptions,
  CustomerDataOptions,
  PaymentDataOptions,
  TemplateDataOptions,
} from "../CustomerDetail";
import Modal from "../../../components/Modal";
import Button, {Variant} from "../../../components/Button";
import {middleware} from "../../../plugins/axios";
import {toastError, toastSuccess} from "../../../plugins/toast";
import Input from "../../../components/Form/Input";
import i18n from "../../../plugins/i18n";
import {
  HasPermission,
  UserPermissions,
} from "../../../components/UserPermission";
import Label from "../../../components/Form/Label";
import ReactSelect from "react-select";
import {
  DamageClaimPayload,
  Option,
  Store,
  TheftClaimPayload,
} from "../customer.types";
import NewClaimModalPersonalInformationTab from "./NewClaimModalPersonalInformationTab";
import NewClaimModalDeviceTab from "./NewClaimModalDeviceTab";
import Translate from "../../../components/Translate";
import customStyles from "../../../utils/customStyles";
import HtmlBlock from "../../../components/HtmlBlock";
import {env} from "../../../env";

interface NewClaimModalProps {
  contract: ContractDataOptions;
  customer: CustomerDataOptions;
  paymentInformation: PaymentDataOptions;
  isOpen: boolean;
  setIsOpen: any;
  organisation: any;
  organisationEmail: string;
  canCreateClaim: boolean;
  hasOpenPayment: boolean;
  openUserEditModal: () => void;
  openPaymentEditModal: () => void;
  openContractEditModal: () => void;
}

const NewClaimModal: React.FC<NewClaimModalProps> = ({
  contract,
  customer,
  paymentInformation,
  isOpen,
  setIsOpen,
  organisation,
  organisationEmail,
  canCreateClaim,
  hasOpenPayment,
  openUserEditModal,
  openPaymentEditModal,
  openContractEditModal,
}) => {
  const [hasAcceptedPersonalInformation, setHasAcceptedPersonalInformation] =
    useState<boolean>(false);
  const [hasAcceptedDevice, setHasAcceptedDevice] = useState<boolean>(false);
  const [selectedDate, setStartDate] = useState<string | undefined>(undefined);
  const [isFindMyDeviceDisabled, setIsFindMyDeviceDisabled] = useState(false);
  const [forceCreate, setForceCreate] = useState<boolean>(false);

  const [claimType, setClaimType] = useState<Option | null>(null);
  const [claimCause, setClaimCause] = useState<Option | null>(null);
  const [claimCauseOptions, setClaimCauseOptions] = useState<Option[]>([]);
  const [claimAreaOptions, setClaimAreaOptions] = useState<Option[]>([]);
  const [claimAreas, setClaimAreas] = useState<Option[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [claimConfigurationCauses, setClaimConfigurationCauses] = useState<
    ClaimConfigurationCause[]
  >([]);

  useEffect(() => {
    if (!claimType) return;

    if (claimType.value === ClaimType.DAMAGE) {
      setIsFindMyDeviceDisabled(true);
    } else {
      setIsFindMyDeviceDisabled(false);
    }

    if (claimType.value === ClaimType.DAMAGE) {
      let comparedTemplateFilter: TemplateDataOptions | null = null;
      if (templateFilters) {
        templateFilters.forEach((templateFilter) => {
          if (contract.template_no == templateFilter.template_no) {
            if (
              !templateFilter.category_code ||
              templateFilter.category_code == contract.category_code
            ) {
              comparedTemplateFilter = templateFilter;
            }
          }
        });
      }

      if (comparedTemplateFilter) {
        setSelectedTemplateFilter(comparedTemplateFilter);
        return;
      } else {
        setSelectedTemplateFilter(null);
      }
    } else {
      setSelectedTemplateFilter(null);
    }

    const _subgroup = contract.subGroup;
    const _category: ObjectCategory | undefined =
      ClaimConfigurationData.objectCategories.find(
        (obj) => obj.name === contract.category.toUpperCase()
      );

    if (!_category) return;

    let causeOptions: Option[] = [];

    setClaimCauseOptions([]);

    // Check if subgroups exist for category. If so, then show the damage types for that subgroup
    if (_category?.subGroups !== undefined && _category?.subGroups.length > 0) {
      const subGroup = _category.subGroups.find(
        (group: any) => group.code === _subgroup
      );

      if (subGroup) {
        let data: ClaimConfigurationCause[] = [];

        if (claimType.value === ClaimType.THEFT) {
          data = [...subGroup.theftCause];
        }

        if (claimType.value === ClaimType.DAMAGE) {
          data = [...subGroup.damageCause];
        }

        setClaimConfigurationCauses(data);

        causeOptions = data.map((obj) => {
          return {
            value: obj.code,
            label: i18n.t(obj.text),
          };
        });
      }
    }

    // If damageCauses is still empty, it means there are no subgroups. Then select the standard damage causes.
    if (!causeOptions.length) {
      let data: ClaimConfigurationCause[] = [];

      if (claimType.value === ClaimType.THEFT) {
        data = [..._category.theftCause];
      }

      if (claimType.value === ClaimType.DAMAGE) {
        data = [..._category.damageCause];
      }

      setClaimConfigurationCauses(data);

      causeOptions = data.map((obj) => {
        return {
          value: obj.code,
          label: i18n.t(obj.text),
        };
      });
    }

    if (!contract.fall_and_impact_damage || contract.fall_and_impact_damage.toLowerCase() !== "yes") {
      const index = causeOptions.findIndex(value => value.value === DamageType.FALL_DAMAGE)
      causeOptions.splice(index, 1);
    }

    if (!contract.technical_defect || contract.technical_defect.toLowerCase() !== "yes") {
      const index = causeOptions.findIndex(value => value.value === DamageType.TECHNICAL_DEFECT)
      causeOptions.splice(index, 1);
    }

    if (!contract.water_damage || contract.water_damage.toLowerCase() !== "yes") {
      const index = causeOptions.findIndex(value => value.value === DamageType.MOISTURE_DAMAGE)
      causeOptions.splice(index, 1);
    }

    setClaimCauseOptions(causeOptions);
    resetClaimData(false);
  }, [claimType]);

  useEffect(() => {
    if (!claimCause) return;
    if (!claimConfigurationCauses.length) return;

    let cause = claimConfigurationCauses.find((cause) => {
      return cause.code === claimCause.value;
    });

    if (cause) {
      setClaimAreaOptions(
        cause.problemArea.map((obj) => {
          return {
            value: obj.code,
            label: i18n.t(obj.text),
          };
        })
      );
    }
  }, [claimCause]);

  const [extraInfo, setExtraInfo] = useState<string>("");

  const [questionOneAnswer, setQuestionOneAnswer] = useState<string>("");
  const [questionTwoAnswer, setQuestionTwoAnswer] = useState<string>("");
  const [questionThreeAnswer, setQuestionThreeAnswer] = useState<string>("");
  const [questionFourAnswer, setQuestionFourAnswer] = useState<string>("");
  const [questionFiveAnswer, setQuestionFiveAnswer] = useState<string>("");
  const [questionSixAnswer, setQuestionSixAnswer] = useState<string>("");

  const [reclaim, setReclaim] = useState<boolean | null>(null);
  const [answeredTruthfully, setAnsweredTruthfully] = useState<boolean>(false);

  const [interval, setInterval] = useState<number | null>(null);
  const [claimID, setClaimID] = useState<string | null>(null);
  const [claimNumber, setClaimNumber] = useState<string | null>(null);
  const [templateNoClaim, setTemplateNoClaim] = useState<string | null>(null);
  const [storeNo, setStoreNo] = useState<string | null>(null);
  const [stores, setStores] = useState<Store[] | null>(null);
  const [showStores, setShowStores] = useState<boolean>(false)
  const [selectedStore, setSelectedStore] = useState({
    value: "",
    label: "",
  });
  const [fullSelectedStore, setFullSelectedStore] = useState<Store | null>(null);
  const [customerNotification, setCustomerNotification] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [templateFilters, setTemplateFilters] = useState<
    TemplateDataOptions[] | null
  >(null);
  const [selectedTemplateFilter, setSelectedTemplateFilter] =
    useState<TemplateDataOptions | null>(null);

  useEffect(() => {
    getTemplateFilters();
    setSelectedTemplateFilter(null);
    setInterval(null);
    setClaimID(null);
    setClaimNumber(null);
    setTemplateNoClaim(null);
    setStoreNo(null);
    setStores(null);
    setShowStores(false)
    setSelectedStore({value: "", label: ""});
    setFullSelectedStore(null);
    setCustomerNotification("");
    setSubmitted(false);
  }, [isOpen]);

  useInterval(() => {
    checkClaimStatus();
  }, interval);

  const startStatusClaimCheck = (claimID: string) => {
    if (!claimID) return;

    setClaimID(claimID);
    setInterval(5000);
  };

  const resetClaimData = (includeType: boolean) => {
    if (includeType) {
      setClaimType(null);
    }

    setStartDate(undefined);
    setClaimCause(null);
    setClaimAreas([]);

    setExtraInfo("");

    setQuestionOneAnswer("");
    setQuestionTwoAnswer("");
    setQuestionThreeAnswer("");
    setQuestionFourAnswer("");
    setQuestionFiveAnswer("");
    setQuestionSixAnswer("");

    setReclaim(null);
    setAnsweredTruthfully(false);
  };

  const checkClaimStatus = () => {
    middleware
      .get(`/claims/${claimID}`)
      .then((res) => {
        const data = res.data;
        if (data.is_routed) {
          // Show navision_ext_id if available
          if (data.navision_ext_id) {
            setClaimNumber(data.navision_ext_id);
          }

          if (data.customer_notification) {
            setCustomerNotification(data.customer_notification);
          }

          // if location in template = true, show all  stores
          if (
            data.location_in_template &&
            (data.on_hold_code === "" ||
              data.on_hold_code === "FOUTIEVE REPARATIE")
          ) {
            setShowStores(true)
            setTemplateNoClaim(data.contract.template_no_claim);
            getStores(data.contract.template_no_claim);
          }

          // if location in template = false, but store_no is filled. Show store information
          if (!data.location_in_template && data.store_no !== "") {
            getStore(data.store_no);
          }

          setInterval(null);
        }
      })
      .catch((e) => {
        console.error(e);
        setInterval(null);
      });
  };

  const getStore = (storeNo: string | null) => {
    middleware
      .get(`supplier/filter?number=${storeNo}`, {
        baseURL: env.REACT_APP_ROUTING_SERVICE_URL,
      })
      .then((res) => {
        const store = res.data;
        const apiStores: Store[] = [];

        apiStores.push({
          active: store.active,
          address: store.address,
          city: store.city,
          claim_template: store.claim_template,
          contact: store.contact,
          country: store.country,
          email: store.email,
          id: store.id,
          is_repairer: store.is_repairer,
          is_store: store.is_store,
          name: store.name,
          number: store.number,
          phone: store.phone,
          sales_channel: store.sales_channel,
          zipcode: store.zipcode,
          label: store.name,
        });

        setStoreNo(storeNo);
        setStores(apiStores);

        setFullSelectedStore(store);
        setSelectedStore({
          value: store.number,
          label: store.name,
        });
      })
      .catch((e) => {
        setStoreNo(null);
        setStores(null);
        console.error(e);
      });
  };

  const onSupplierSearch = (value: string) => {
    if (templateNoClaim) {
      const zipCodeRegex = /^(\d{4}\s?[A-Za-z]{2}|\d{5}\s?[A-Za-z]{2})$/;

      if (zipCodeRegex.test(value)) {
        getStores(templateNoClaim,value);
      } else if (value.length >= 3) {
        getStores(templateNoClaim,"", value);
      } else {
        getStores(templateNoClaim)
      }
    }
  }

  const getStores = (salesChannel: string, zipcode: string = "", city: string = "") => {
    let url = `supplier/all/filter?saleschannel=${salesChannel}`

    if (zipcode != "") {
      url = `supplier/all/filter?saleschannel=${salesChannel}&zipcode=${zipcode}`;
    }

    if (city) {
      url = `supplier/all/filter?saleschannel=${salesChannel}&city=${city}`;
    }

    middleware
      .get(url, {
        baseURL: env.REACT_APP_ROUTING_SERVICE_URL,
      })
      .then((res) => {
        const apiStores: Store[] = [];
        Object.values(res.data.suppliers).forEach((store: any) => {
          let label = store.name;
          if (store.distance) {
            label = `${store.name} (${store.distance.toFixed(2)}km)`;
          }
          apiStores.push({
            active: store.active,
            address: store.address,
            city: store.city,
            claim_template: store.claim_template,
            contact: store.contact,
            country: store.country,
            email: store.email,
            id: store.id,
            is_repairer: store.is_repairer,
            is_store: store.is_store,
            name: store.name,
            number: store.number,
            phone: store.phone,
            sales_channel: store.sales_channel,
            zipcode: store.zipcode,
            label: label,
          });
        });

        setStores(apiStores);
      })
      .catch((e) => {
        setStores(null);
      });
  };

  const getTemplateFilters = () => {
    middleware.get(`claims/templates`).then((res) => {
      setTemplateFilters(res.data);
    });
  };

  const updateClaimStore = () => {
    if (!stores || !stores.length) {
      toastError(i18n.t("detail.claim_update.error"));
      return;
    }

    const data = stores?.find((store) => store.number === selectedStore.value);

    setSubmitted(true);

    middleware
      .post("/claims/claim-route/update-store", {
        claim_id: claimID,
        store_no: data?.number,
      })
      .then((res) => {
        toastSuccess(i18n.t("detail.claim_update.success"));
        setSubmitted(false);
        setIsOpen(false);
      })
      .catch((e) => {
        console.error(e);
        toastError(i18n.t("detail.claim_update.error"));
        setSubmitted(false);
      });
  };

  const areaIsSet = () => {
    return claimAreas.length > 0;
  };

  const handleQuestionSixInput = (e: any) => {
    if (e.target.id === "yes-reclaim") {
      if (reclaim) return;
      setReclaim(e.target.checked);
    }
    if (e.target.id === "no-reclaim") {
      if (reclaim !== null && !reclaim) return;
      setReclaim(!e.target.checked);
    }
  };

  const getAreaString = () => {
    let areas: Option[] = claimAreas;

    if (areas.length === 0) return "";
    let result: string = `${areas[0].value}`;
    areas.slice(1).forEach((el) => {
      result = `${result}, ${el.value}`;
    });

    if (result === "BEHUIZING, SCHERM") {
      result = "SCHERM, BEHUIZING";
    }
    return result;
  };

  const getTheftExplanation = () => {
    let areas: Option[] = claimAreas;

    if (areas.length === 0) return "";
    let result: string = `${areas[0].value}`;
    areas.slice(1).forEach((el) => {
      result = `${result}, ${el.value}`;
    });
    return result;
  };

  const submitClaim = () => {
    if (!claimType) return;
    if (claimType.value === ClaimType.DAMAGE) {
      submitDamageClaim();
    } else {
      submitTheftClaim();
    }
  };

  const defaultValue: Option = {
    label: "Maak een keuze",
    value: "",
  };

  const submitTheftClaim = () => {
    if (!claimType || !claimCause || !selectedDate) return;

    const payload: TheftClaimPayload = {
      claim_type: claimType.value.toString(),
      contract: contract.contract_id,
      product_id: selectedProductId,
      type: claimCause.value.toString(),
      date: selectedDate.replace("/", "-"),
      area: getTheftExplanation(),
      culprit_question: i18n.t("detail.claim_create.theft_question_4"),
      culprit: questionFourAnswer,
      location_question: i18n.t("detail.claim_create.theft_question_3"),
      location: questionThreeAnswer,
      circumstances_question: i18n.t("detail.claim_create.theft_question_2"),
      circumstances: questionTwoAnswer,
      timing_question: i18n.t("detail.claim_create.theft_question_1"),
      timing: questionOneAnswer,
      witnesses_question: i18n.t("detail.claim_create.theft_question_5"),
      witnesses: questionFiveAnswer,
      other_question: i18n.t("detail.claim_create.theft_question_6"),
      other: questionSixAnswer,
      description: extraInfo,
      organisation: organisation.name,
      organisation_email: organisationEmail,
      find_device: isFindMyDeviceDisabled,
      claim_unique: uuidv4()
    };

    setSubmitted(true);

    middleware
      .post(`claims/service/create/${customer.id}`, payload)
      .then((res) => {
        startStatusClaimCheck(res.data.id);
        setSubmitted(false);

        toastSuccess(i18n.t("detail.claim_create.success"));
      })
      .catch((e) => {
        toastError(i18n.t("detail.claim_create.error"), e.message);
        setSubmitted(false);
      });
  };

  const submitDamageClaim = () => {
    if (
      !claimType ||
      !selectedDate ||
      !claimCause ||
      reclaim === null ||
      !answeredTruthfully
    )
      return;

    const payload: DamageClaimPayload = {
      claim_type: claimType.value.toString(),
      contract: contract.contract_id,
      product_id: selectedProductId,
      type: claimCause.value.toString(),
      date: selectedDate.replace("/", "-"),
      areas: getAreaString(),
      culprit_question: i18n.t("detail.claim_create.damage_question_4"),
      culprit: questionFourAnswer,
      cause_question: i18n.t("detail.claim_create.damage_question_5"),
      cause: questionFiveAnswer,
      location_question: i18n.t("detail.claim_create.damage_question_2"),
      location: questionTwoAnswer,
      discovery_question: i18n.t("detail.claim_create.damage_question_3"),
      discovery: questionThreeAnswer,
      origin_question: i18n.t("detail.claim_create.damage_question_1"),
      origin: questionOneAnswer,
      other_question: i18n.t("detail.claim_create.damage_question_6"),
      other: questionSixAnswer,
      reclaim_question: i18n.t("detail.claim_create.reclaim"),
      reclaim: reclaim,
      description: extraInfo,
      organisation: organisation.name,
      organisation_email: organisationEmail,
      claim_unique: uuidv4()
    };

    setSubmitted(true);

    middleware
      .post(`claims/service/create/${customer.id}`, payload)
      .then((res) => {
        startStatusClaimCheck(res.data.id);
        setSubmitted(false);

        toastSuccess(i18n.t("detail.claim_create.success"));
      })
      .catch((e) => {
        toastError(i18n.t("detail.claim_create.error"), e.message);
        setSubmitted(false);
      });
  };

  const canViewClaimType = (option: ClaimType): boolean => {
    if (HasPermission(UserPermissions.CREATE_CLAIMS_ALL)) return true;

    const claim_types: Map<ClaimType, UserPermissions> = new Map<
      ClaimType,
      UserPermissions
    >([
      [ClaimType.DAMAGE, UserPermissions.CREATE_CLAIMS_DAMAGE],
      [ClaimType.THEFT, UserPermissions.CREATE_CLAIMS_THEFT],
    ]);

    let perm = claim_types.get(option);
    return !!perm && HasPermission(perm);
  };

  const handleDateKeyPress = (event: any) => {
    event.preventDefault();
  };

  const determineStep = () => {
    if (hasOpenPayment && !forceCreate) {
      return (
        <div className="text-gray-800 mb-5">
          {i18n.t("detail.contracts.has_open_payment_text")}
        </div>
      );
    }

    if (!canCreateClaim && !forceCreate) {
      return (
        <div className="text-gray-800 mb-5">
          {i18n.t("detail.contracts.cannot_create_claim_text")}
        </div>
      );
    }

    if (!hasAcceptedPersonalInformation) {
      return (
        <NewClaimModalPersonalInformationTab
          customer={customer}
          iban={
            paymentInformation.data.filter((pm) => pm.key === "iban")[0]
              .value || "-"
          }
          setIsOpen={setIsOpen}
          openUserEditModal={openUserEditModal}
          openPaymentEditModal={openPaymentEditModal}
        />
      );
    }

    if (!hasAcceptedDevice) {
      return (
        <NewClaimModalDeviceTab
          contract={contract}
          setIsOpen={setIsOpen}
          onProductChange={(productId) => {
            setSelectedProductId(productId)
          }}
          openContractEditModal={openContractEditModal}
        />
      );
    }

    return step1();
  };

  const step1 = () => (
    <div className="min-h-144">
      {/* Claim type */}
      <div className="mb-4">
        {!!claimType && claimType.value === ClaimType.THEFT && !contract.theft_coverage && (
          <div className="mb-4">
            <div className="text-red-500">
              {i18n.t("report.common.product_disabled.theft_no_coverage")}
            </div>
          </div>
        )}
        <Select
          label=""
          translationKey="detail.claim_create.claim_type_label"
          selected={claimType ? claimType : defaultValue}
          setSelected={setClaimType}
          id="claim_type"
          name="claim_type"
          options={claimTypeOptions
            .map((option) => {
              if (!canViewClaimType(option.value)) return undefined;
              return {
                value: option.value,
                label: i18n.t(
                  `service.claim.claim_input_options.${option.value
                    .toLowerCase()
                    .replace(" ", "_")}`
                ),
              };
            })
            .filter((o) => o)} // last filter to remove 'undefined' from array to prevent empty option
        />
      </div>
      {!!claimType &&
        claimType.value === ClaimType.DAMAGE &&
        selectedTemplateFilter && (
          <div>
            <div className="text-2xl text-gray-800 font-semibold">
              <Translate name={"report.template_filter.title"}/>
              <div className="text-sm font-medium text-gray-800 mt-5 mb-5">
                <HtmlBlock html={selectedTemplateFilter.service_information}/>
              </div>
            </div>
          </div>
        )}
      {/* Select date */}
      {!!claimType &&
        (claimType.value === ClaimType.DAMAGE || contract.theft_coverage) &&
        !selectedTemplateFilter && (
          <div className="mb-4">
            <Input
              label=""
              translationKey="detail.claim_create.claim_date_label"
              type="date"
              min={new Date(contract.starting_date).toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
              value={selectedDate}
              onChange={(e) => setStartDate(e.target.value)}
              onKeyPress={(e) => handleDateKeyPress(e)}
            />
          </div>
        )}
      {/* Claim type and date */}
      {/* Claim cause */}
      {!!claimType && !!selectedDate && (
        <div className="mb-4">
          <Select
            label=""
            translationKey={
              claimType.value === ClaimType.DAMAGE
                ? "detail.claim_create.damage_type_label"
                : "detail.claim_create.theft_type_label"
            }
            selected={claimCause ? claimCause : defaultValue}
            setSelected={setClaimCause}
            id="damage_type"
            name="damage_type"
            options={claimCauseOptions}
          />
        </div>
      )}

      {/* Claim type and date and damage type */}
      {/* Claim area */}
      {!!claimType && !!selectedDate && !!claimCause && (
        <div className="mb-4">
          <CheckButtonList
            selectSingle={true}
            selected={claimAreas ? claimAreas : []}
            setSelected={setClaimAreas}
            options={claimAreaOptions}
          />
        </div>
      )}

      {/* Claim type and date and damage type and area */}
      {/* Give extra info */}
      {!!claimType && !!selectedDate && areaIsSet() && (
        <div className="mb-4">
          <Textarea
            label=""
            translationKey={
              claimType.value === ClaimType.DAMAGE
                ? "detail.claim_create.extra_info_label"
                : "detail.claim_create.theft_description_label"
            }
            value={extraInfo}
            onChange={(e) => setExtraInfo(e.target.value)}
            limit={250}
          />
        </div>
      )}

      {/* Claim type and date and damage type and area and extra info */}
      {/* Answer questions */}
      {!!claimType && !!selectedDate && areaIsSet() && extraInfo.length > 0 && (
        <>
          <div className="mb-4">
            <Textarea
              label=""
              translationKey={
                claimType.value === ClaimType.DAMAGE
                  ? "detail.claim_create.damage_question_1"
                  : "detail.claim_create.theft_question_1"
              }
              value={questionOneAnswer}
              onChange={(e) => setQuestionOneAnswer(e.target.value)}
              limit={250}
            />
          </div>
          <div className="mb-4">
            <Textarea
              label=""
              translationKey={
                claimType.value === ClaimType.DAMAGE
                  ? "detail.claim_create.damage_question_2"
                  : "detail.claim_create.theft_question_2"
              }
              value={questionTwoAnswer}
              onChange={(e) => setQuestionTwoAnswer(e.target.value)}
              limit={250}
            />
          </div>
          <div className="mb-4">
            <Textarea
              label=""
              translationKey={
                claimType.value === ClaimType.DAMAGE
                  ? "detail.claim_create.damage_question_3"
                  : "detail.claim_create.theft_question_3"
              }
              value={questionThreeAnswer}
              onChange={(e) => setQuestionThreeAnswer(e.target.value)}
              limit={250}
            />
          </div>
          <div className="mb-4">
            <Textarea
              label=""
              translationKey={
                claimType.value === ClaimType.DAMAGE
                  ? "detail.claim_create.damage_question_4"
                  : "detail.claim_create.theft_question_4"
              }
              value={questionFourAnswer}
              onChange={(e) => setQuestionFourAnswer(e.target.value)}
              limit={250}
            />
          </div>
          <div className="mb-4">
            <Textarea
              label=""
              translationKey={
                claimType.value === ClaimType.DAMAGE
                  ? "detail.claim_create.damage_question_5"
                  : "detail.claim_create.theft_question_5"
              }
              value={questionFiveAnswer}
              onChange={(e) => setQuestionFiveAnswer(e.target.value)}
              limit={250}
            />
          </div>
          <div className="mb-4">
            <Textarea
              label=""
              translationKey={
                claimType.value === ClaimType.DAMAGE
                  ? "detail.claim_create.damage_question_6"
                  : "detail.claim_create.theft_question_6"
              }
              value={questionSixAnswer}
              onChange={(e) => setQuestionSixAnswer(e.target.value)}
              limit={250}
            />
          </div>
          {/* Find my device checkbox */}
          {contract.manufacturer === "Apple" &&
            (claimType.value === ClaimType.DAMAGE ? (
              <div className={"mb-4"}>
                <Checkbox
                  name="findMyDevice"
                  label=""
                  translationKey="detail.claim_create.find_my_device_damage"
                  onChange={(e) => setIsFindMyDeviceDisabled(e.target.checked)}
                  checked={isFindMyDeviceDisabled}
                />
              </div>
            ) : (
              <div className={"mb-4"}>
                <Checkbox
                  name="findMyDevice"
                  label=""
                  translationKey="detail.claim_create.find_my_device_theft"
                  onChange={(e) => setIsFindMyDeviceDisabled(!e.target.checked)}
                  checked={!isFindMyDeviceDisabled}
                />
              </div>
            ))}
        </>
      )}

      {/* Claim type and date and damage type and area and extra info and questions */}
      {/* Answer last questions  */}
      {!!claimType &&
        !!selectedDate &&
        claimType.value === ClaimType.DAMAGE &&
        areaIsSet() &&
        extraInfo.length > 0 &&
        questionOneAnswer.length > 0 &&
        questionTwoAnswer.length > 0 &&
        questionThreeAnswer.length > 0 &&
        questionFourAnswer.length > 0 &&
        questionFiveAnswer.length > 0 && (
          <>
            <span>
              <Translate name={"detail.claim_create.reclaim"}/>
            </span>
            <div>
              <div className="mr-3">
                <Checkbox
                  checked={reclaim ? reclaim : false}
                  id="yes-reclaim"
                  label="Yes"
                  onChange={handleQuestionSixInput}
                />
              </div>
              <div>
                <Checkbox
                  checked={reclaim !== null ? !reclaim : false}
                  id="no-reclaim"
                  label="No"
                  onChange={handleQuestionSixInput}
                />
              </div>
            </div>
          </>
        )}

      {/* Ask if answered truthfully before continuing */}
      {/* Answer last questions  */}
      {!!claimType &&
        !!selectedDate &&
        claimType.value === ClaimType.DAMAGE &&
        areaIsSet() &&
        extraInfo.length > 0 &&
        questionOneAnswer.length > 0 &&
        questionTwoAnswer.length > 0 &&
        questionThreeAnswer.length > 0 &&
        questionFourAnswer.length > 0 &&
        questionFiveAnswer.length > 0 &&
        reclaim !== null && (
          <>
            <span>
              <Translate name={"detail.claim_create.answered_truthfully"}/>
            </span>
            <div>
              <div className="mr-3">
                <Checkbox
                  checked={answeredTruthfully}
                  id="yes-truth"
                  label="Yes"
                  onChange={(e) => setAnsweredTruthfully(e.target.checked)}
                />
              </div>
            </div>
          </>
        )}

      {/* Ask if answered truthfully before continuing */}
      {/* Answer last questions  */}
      {!!claimType &&
        !!selectedDate &&
        claimType.value === ClaimType.THEFT &&
        areaIsSet() &&
        extraInfo.length > 0 &&
        questionOneAnswer.length > 0 &&
        questionTwoAnswer.length > 0 &&
        questionThreeAnswer.length > 0 &&
        questionFourAnswer.length > 0 &&
        questionFiveAnswer.length > 0 && (
          <>
            <span>
              <Translate name={"detail.claim_create.answered_truthfully"}/>
            </span>
            <div>
              <div className="mr-3">
                <Checkbox
                  checked={answeredTruthfully}
                  id="yes-truth"
                  label="Yes"
                  onChange={(e) => setAnsweredTruthfully(e.target.checked)}
                />
              </div>
            </div>
          </>
        )}
    </div>
  );

  const getButtonForStep = () => {
    if (hasOpenPayment) {
      return (<></>)
    }

    if (!canCreateClaim && !forceCreate) {
      return (
        <Button onClick={() => setForceCreate(true)} variant={Variant.PURPLE}>
          {i18n.t("detail.contracts.force_create_claim")}
        </Button>
      );
    }

    if (!hasAcceptedPersonalInformation) {
      return (
        <Button
          onClick={() => setHasAcceptedPersonalInformation(true)}
          variant={Variant.PURPLE}
        >
          {i18n.t("detail.contracts.accept_personal_information")}
        </Button>
      );
    }

    if (!hasAcceptedDevice) {
      return (
        <Button
          onClick={() => setHasAcceptedDevice(true)}
          variant={Variant.PURPLE}
        >
          {i18n.t("detail.contracts.accept_device")}
        </Button>
      );
    }

    return (
      <Button
        disabled={submitted}
        onClick={() => submitClaim()}
        variant={answeredTruthfully ? Variant.PURPLE : Variant.LIGHT_PURPLE}
      >
        {i18n.t("detail.common.save")}
      </Button>
    );
  };

  const closeModal = () => {
    setIsOpen(false);
    resetClaimData(true);
    setForceCreate(false);
    setHasAcceptedDevice(false);
    setHasAcceptedPersonalInformation(false);
  };

  return (
    <Modal
      allowCloseOnBody={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth="max-w-4xl"
    >
      {!claimID && (
        <>
          <Modal.Header>
            <div className="text-2xl text-gray-800 font-semibold">
              <Translate name={"detail.claim_create.header"}/>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="min-h-32">{determineStep()}</div>
          </Modal.Body>

          <Modal.Footer>
            <div>
              <Button
                onClick={() => {
                  closeModal();
                }}
                variant={Variant.TRANSPARENT}
              >
                <Translate name={"detail.common.back_to_detail"}/>
              </Button>
            </div>
            <div>{getButtonForStep()}</div>
          </Modal.Footer>
        </>
      )}

      {claimID && (
        <>
          <Modal.Header>Update claim</Modal.Header>

          <Modal.Body>
            <div className="min-h-144">
              <p className="mb-6">
                <Translate name={"detail.common.claim_number"}/>:{" "}
                {claimNumber ? claimNumber : i18n.t("common.loading")}
              </p>

              {customerNotification !== "" && (
                <div>
                  <span>
                    <Translate name={"detail.common.customer_notification"}/>:
                  </span>
                  <p className="mb-6">{customerNotification}</p>
                </div>
              )}

              {showStores && (
                <>
                <div>
                  <div className="mb-4">
                    <Label id="manufacturer">
                      <Translate
                        name={"detail.claim_input_options.select_option"}
                      />
                    </Label>
                    <ReactSelect
                      onChange={(e: any) => {
                        setSelectedStore(e);
                        const findStore = stores?.find((v) => v.number === e.value);
                        if (findStore) {
                          setFullSelectedStore(findStore);
                        }
                      }}
                      styles={customStyles}
                      classNamePrefix="select"
                      isClearable={false}
                      isSearchable={true}
                      filterOption={() => true}
                      onInputChange={(newValue) => {
                        onSupplierSearch(newValue);
                      }}
                      name="store_selection"
                      options={stores ? stores.map((option) => ({
                        value: option.number,
                        label: option.label,
                      })) : []}
                    />
                  </div>

                  {fullSelectedStore && selectedStore.value !== "" && (
                    <div>
                      <ul className="mt-6 list-none">
                        <li>
                          <Translate
                            name={"detail.common.store.name"}
                          />
                          : {fullSelectedStore.name}
                        </li>
                        <li>
                          <Translate
                            name={"detail.common.store.address"}
                          />
                          : {fullSelectedStore.address}
                        </li>
                        <li>
                          <Translate
                            name={"detail.common.store.zipcode"}
                          />
                          : {fullSelectedStore.zipcode}
                        </li>
                        <li>
                          <Translate
                            name={"detail.common.store.city"}
                          />
                          : {fullSelectedStore.city}
                        </li>
                        <li>
                          <Translate
                            name={"detail.common.store.email"}
                          />
                          : {fullSelectedStore.email}
                        </li>
                        <li>
                          <Translate
                            name={"detail.common.store.phone"}
                          />
                          : {fullSelectedStore.phone}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                onClick={() => {
                  setIsOpen(false);
                  resetClaimData(true);
                }}
                variant={Variant.TRANSPARENT}
              >
                <Translate name={"detail.common.back_to_detail"}/>
              </Button>
            </div>
            {showStores && selectedStore.value !== "" && (
              <div>
                <Button disabled={submitted} onClick={() => updateClaimStore()}>
                  <Translate name={"detail.common.save"}/>
                </Button>
              </div>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default NewClaimModal;
